import {useEffect, useState} from "react";
import {Navigate, Route, Routes} from 'react-router-dom';
import LoginPage from "@pages/Login";
import DefaultLayout from "./layout/DefaultLayout";
import api from "@modules/api";
import {Spin} from "antd";
import UserContext from "@context/UserContext.js";

const App = () => {
	const [isLogin, setIsLogin] = useState(!!localStorage.getItem("token"));
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null);

	const toggleLoading = () => setLoading(prev => !prev);

	useEffect(() => {
		(async () => {
			if (isLogin) {
				toggleLoading()
				const getUserSelf = async () => {
					const response = await api('user/self');
					if (response.status === 200) {
						return response.data;
					} else await onLogout();
				};
				const response = await getUserSelf()
				if (response) {
					setUser(response);
				}
				toggleLoading()
			}
		})()
	}, [isLogin])

	const onLogout = async () => {
		await api("auth/logout", {
			method: "POST",
		});
		localStorage.removeItem("token");
		setIsLogin(false);
	};

	return (
		<Spin spinning={loading}>
			<UserContext.Provider value={user}>
				<Routes>
					<Route exact
								 path="/login"
								 name="Авторизация"
								 element={isLogin ? <Navigate to={{pathname: "/"}}/> : <LoginPage toggleIsLogin={setIsLogin}/>}
					/>
					<Route path="*"
								 name="Главная"
								 element={isLogin
									 ? (<DefaultLayout logout={onLogout} setUser={setUser}/>)
									 : (<Navigate to={{pathname: "/login"}}/>)
								 }
					/>
				</Routes>
			</UserContext.Provider>
		</Spin>
	);
}

export default App;
