import {useEffect, useState} from "react";
import {Bill} from "@components/Bill/Bill";
import {
  Space,
  Button,
  Modal,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Upload,
  message,
  Result,
} from "antd";
import {Filters} from "@components/BillFilters/Filters";
import {UploadOutlined, SearchOutlined} from "@ant-design/icons";
import api from "@modules/api";
import {numberFormat, parseCurrencyString} from "@modules/common";
import "./index.css";

const MyBill = ({toggleLoading}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCreateBill, setIsCreateBill] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [firms, setFirms] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [bills, setBills] = useState([]);
  const [bill, setBill] = useState({
    priority: "low",
    files: [],
  });
  const [filters, setFilters] = useState({
    firm: undefined,
    org: undefined,
    exp: undefined,
    create: undefined,
    type: undefined,
    id: "",
    priority: undefined,
    paid: false,
    confirmation: false,
    canceled: false,
  });

  const mainContent = document.getElementsByTagName("main")[0];

  const toTop = () => mainContent.scrollTo({top: 0, behavior: "smooth"});

  const fetchInitialData = async () => {
    await Promise.all([fetchDataFromBill(), getData()]);
  };

  useEffect(() => {
    (async () => {
      await fetchInitialData();
      toggleLoading();
    })();
  }, []);

  const updateData = (filters, offset = 0) => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });
    getFiltredBills(newParams, offset);
  };

  const setFilterByTitle = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }

    const newFilters = {...filters, ...updatedFilters};
    setFilters(newFilters);
  };

  const setOrgs = (ids) => {
    if (!ids.length) {
      setFilters({...filters, org: undefined, type: undefined});
      return;
    }
    const types = [];
    ids.forEach((id) => {
      const org = organizations.find((org) => org.id === id);
      if (org) {
        types.push(org.typeOrganization);
      }
    });
    const newFilters = {...filters, org: ids, type: types};
    setFilters(newFilters);
  };

  const idSearch = () => {
    const newParams = new URLSearchParams();
    newParams.set("id", filters.id);
    toTop();
    getFiltredBills(newParams, 0);
  };

  const applyFilters = () => {
    toTop();
    updateData(filters);
  };

  const resetBill = () =>
    setBill({
      priority: "low",
      billSum: null,
      files: [],
    });

  const resetFilters = () => {
    const newFilters = {
      ...filters,
      firm: undefined,
      org: undefined,
      type: undefined,
      exp: undefined,
      create: undefined,
      id: "",
      priority: undefined,
      paid: false,
      confirmation: false,
      canceled: false,
    };
    setFilters(newFilters);
    toTop();
    updateData(newFilters);
  };

  const getMyBills = async (params, offset) => {
    const response = await api(
      `bill/my?offset=${offset}${params.size ? `&${params}` : ""}`
    );
    if (response.status === 200) {
      if (offset === 0) {
        setBills(response.data);
      } else setBills([...bills, ...response.data]);
    }
  };

  const getData = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });

    await getMyBills(newParams, 0);
  };

  const getFiltredBills = async (params, offset) => {
    toggleLoading();
    await getMyBills(params, offset);
    toggleLoading();
  };

  const setNewBill = (data) =>
    setBill({
      ...bill,
      ...data,
    });

  const toggleIsOpenModal = () => setIsOpenModal((prev) => !prev);

  const fetchDataFromBill = async () => {
    const response = await api(
      `bill/data_from_create?author=${filters.author}`
    );
    if (response.status === 200) {
      setExpenses(response.data.expenses);
      setFirms(response.data.firms);
      setOrganizations(
        response.data.organization.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
      if (response.data.organization.length === 1) {
        setNewBill({
          organization: 0,
        });
      }
    }
  };

  const openModal = async () => {
    toggleIsOpenModal();
  };

  const closeModal = () => {
    resetBill();
    toggleIsOpenModal();
  };

  const createBill = async () => {
    setIsCreateBill(true);
    const response = await api("bill/create", {
      method: "POST",
      body: {
        ...bill,
        files: bill.files.map((file) => file.response.file.filename),
        organization: organizations[bill.organization],
      },
    });
    setBills([
      {
        ...response.data,
        expenses: expenses.find((el) => el.id === bill.expenses),
        files: bill.files,
        firm: firms.find((el) => el.id === bill.firm),
        organization: organizations[bill.organization],
        priority: bill.priority,
        howToPay: bill.howToPay,
        comment: bill.comment,
        sum: bill.billSum,
      },
      ...bills,
    ]);
    resetBill();
    setIsCreateBill(false);
    toggleIsOpenModal();
  };

  const beforeUpload = (file) => {
    const mimeType = ["image/jpeg", "image/png", "application/pdf"];
    const isIMG = mimeType.some((type) => file.type === type);
    if (!isIMG) {
      message.error(`${file.name} не поддерживаемый формат файла`, 5);
    }
    return isIMG || Upload.LIST_IGNORE;
  };

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.response) {
        file.url = `api/upload/${file.response.file.filename}`;
      }
      return file;
    });
    setNewBill({
      files: newFileList,
    });
  };

  const setExpenseToBill = (expenses) => {
    setNewBill({expenses});
  };

  const setHowToPay = (e) => {
    setNewBill({howToPay: e.target.value});
  };

  const setBillSum = (e) => {
    setNewBill({billSum: e});
  };

  const setFirm = (firm) => {
    setNewBill({firm});
  };

  const setOrganization = (organization) => {
    setNewBill({organization});
  };

  const setComment = (e) => {
    setNewBill({comment: e.target.value});
  };

  const setPriority = (priority) => {
    setNewBill({priority});
  };

  const objectList = [
    {
      key: "expenses",
      title: "Статья расходов",
      component: (
        <Select
          style={{width: "100%"}}
          placeholder={"Выберете статью расходов"}
          value={bill.expenses}
          onChange={setExpenseToBill}
          options={expenses.map((el) => ({
            value: el.id,
            label: `${el.number} ${el.name}`,
            key: el.id,
          }))}
        />
      ),
    },
    {
      key: "howToPay",
      title: "За что платим",
      component: (
        <Input
          placeholder="Введите текст"
          onChange={setHowToPay}
          value={bill.howToPay}
        />
      ),
    },
    {
      key: "billFile",
      title: "Копия счета",
      component: (
        <Upload
          action="api/upload"
          listType="picture"
          beforeUpload={beforeUpload}
          multiple={true}
          onChange={handleChange}
          fileList={bill.files}
        >
          <Button type="primary" icon={<UploadOutlined />}>
            Добавить
          </Button>
        </Upload>
      ),
    },
    {
      key: "billSum",
      title: "Сумма счета",
      component: (
        <InputNumber
          min={0}
          controls={false}
          placeholder="Введите сумму счета"
          step={"0.01"}
          style={{width: "100%"}}
          addonAfter={"RUB"}
          onChange={setBillSum}
          formatter={numberFormat}
          parser={parseCurrencyString}
          value={bill.billSum}
        />
      ),
    },
    {
      key: "firm",
      title: "Фирма",
      component: (
        <Select
          style={{width: "100%"}}
          placeholder={"Выберете фирму"}
          value={bill.firm}
          onChange={setFirm}
          options={firms.map((el) => ({
            value: el.id,
            label: `${el.name}`,
            key: el.id,
          }))}
        />
      ),
    },
    {
      key: "organization",
      title: "Филиал",
      component: (
        <Select
          style={{width: "100%"}}
          placeholder={"Выберете филиал"}
          value={bill.organization}
          onChange={setOrganization}
          options={organizations.map((el, i) => ({
            value: i,
            label: el.label,
            key: i,
          }))}
        />
      ),
    },
    {
      key: "comment",
      title: "Комментарий",
      component: (
        <Input
          placeholder="Введите текст"
          onChange={setComment}
          value={bill.comment}
        />
      ),
    },
    {
      key: "priority",
      title: "Приоритет",
      component: (
        <Select
          style={{width: "100%"}}
          value={bill.priority}
          onChange={setPriority}
          options={[
            {
              value: "low",
              label: "Низкий",
            },
            {
              value: "medium",
              label: "Средний",
            },
            {
              value: "high",
              label: "Высокий",
            },
          ]}
        />
      ),
    },
  ];

  const checkRequired = () => {
    return (
      bill.files.length === 0 ||
      !bill.expenses ||
      !bill.firm ||
      !Object.hasOwn(bill, "organization") ||
      !bill.howToPay ||
      !bill.billSum
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        position: "relative",
      }}
    >
      <div style={{width: "100%"}}>
        {bills.map((el) => (
          <Bill bill={el} key={el.id} />
        ))}
        {bills.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              type={"primary"}
              onClick={() => updateData(filters, bills.length)}
            >
              Загрузить ещё
            </Button>
          </div>
        ) : null}
        {!bills.length && (
          <Result
            className="no-bills"
            icon={<SearchOutlined />}
            status="info"
            title="Согласований не найдено"
          />
        )}
      </div>
      <div>
        <Filters
          mode={"my"}
          applyFilters={applyFilters}
          filters={filters}
          firms={firms}
          orgs={organizations}
          expenses={expenses}
          setOrgs={setOrgs}
          idSearch={idSearch}
          openModal={openModal}
          resetFilters={resetFilters}
          setFilterByTitle={setFilterByTitle}
        />
      </div>
      <Modal
        open={isOpenModal}
        width={1000}
        title={"Создание согласования"}
        okText={"Создать"}
        cancelText={"Закрыть"}
        onCancel={closeModal}
        onOk={createBill}
        cancelButtonProps={{
          disabled: isCreateBill,
        }}
        okButtonProps={{
          loading: isCreateBill,
          disabled: checkRequired(),
        }}
        maskClosable={false}
        closable={false}
      >
        <Space direction={"vertical"} style={{width: "100%"}}>
          <>
            {objectList.map((obj) => (
              <Row key={obj.key} style={{alignItems: "center"}}>
                <Col span={4}>
                  <div
                    style={
                      obj.key !== "priority" && obj.key !== "comment"
                        ? {
                            backgroundColor: "#f7baba",
                            padding: "0 5px",
                            borderRadius: "5px",
                          }
                        : {}
                    }
                  >
                    {obj.title}
                  </div>
                </Col>
                <Col span={19} offset={1}>
                  {obj.component}
                </Col>
              </Row>
            ))}
          </>
        </Space>
      </Modal>
    </div>
  );
};

export default MyBill;
