import {useRef} from "react";

export const useDebaunce = () => {
  const timer = useRef(null);

  return [
    (cb, timeout = 800) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(cb, timeout);
    },
    () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  ];
};
