import React, {useEffect} from "react";
import {
  Card,
  Typography,
  Space,
  DatePicker,
  InputNumber,
  Button,
  Select,
  Checkbox,
} from "antd";
import {SearchOutlined, PlusOutlined} from "@ant-design/icons";
import {useDebaunce} from "@modules/useDebounce";
import api from "@modules/api";
import dayjs from "dayjs";

export const Filters = ({
  filters,
  firms,
  orgs,
  expenses,
  authors,
  setFilterByTitle,
  idSearch,
  setAuthorOptions,
  setOrgs,
  resetFilters,
  applyFilters,
  setMode,
  openModal,
  mode,
}) => {
  const [debaunce, clearDebaunce] = useDebaunce();

  const getAuthors = async (value) => {
    const authors = await api(`user/search/${value}`);
    if (authors.status) {
      setAuthorOptions(authors.data);
    }
  };

  const handleSearch = (e) => {
    if (e.length >= 3) {
      debaunce(() => {
        getAuthors(e);
      });
    }
  };

  const handleDatePicker = (e, key) => {
    const start = e[0] ? e[0].format("YYYY-MM-DD") : undefined;
    const end = e[1] ? e[1].format("YYYY-MM-DD") : undefined;

    if (start && end) {
      setFilterByTitle(key, [start, end]);
    } else {
      setFilterByTitle(key, undefined);
    }
  };

  useEffect(() => {
    return clearDebaunce;
  }, []);

  return (
    <div style={{position: "sticky", top: 0}}>
      {mode === "payment" ? (
        <Card style={{marginBottom: "10px"}} styles={{body: {padding: "12px"}}}>
          <Typography
            style={{fontSize: "20px", fontWeight: 600, marginBottom: "8px"}}
          >
            Подраздел
          </Typography>
          <Space direction="vertical">
            <Space>
              <Select
                style={{minWidth: "300px"}}
                onChange={(e) => setMode(e)}
                defaultValue={"forPass"}
                placeholder={"Подраздел"}
                options={[
                  {label: "На согласование и оплату", value: "forPass"},
                  {label: "Пройденные", value: "passed"},
                ]}
                value={filters.mode}
              />
            </Space>
          </Space>
        </Card>
      ) : (
        ""
      )}
      {mode === "my" ? (
        <Button
          type={"primary"}
          size="large"
          style={{marginBottom: 5, width: "100%"}}
          onClick={openModal}
        >
          <PlusOutlined /> Создать согласование
        </Button>
      ) : (
        ""
      )}
      <Card styles={{body: {padding: "12px"}}}>
        <Typography
          style={{fontSize: "20px", fontWeight: 600, marginBottom: "8px"}}
        >
          Фильтры
        </Typography>
        <Space direction="vertical">
          <Space.Compact style={{width: 300}}>
            <InputNumber
              controls={false}
              min="1"
              style={{width: "100%"}}
              placeholder="Номер согласования"
              defaultValue={filters.id}
              value={filters.id}
              onChange={(e) => setFilterByTitle("id", e, true)}
            />
            <Button
              type="primary"
              onClick={idSearch}
              icon={<SearchOutlined />}
            />
          </Space.Compact>
          <Space>
            <Select
              style={{minWidth: "300px", maxWidth: "300px"}}
              onChange={(e) =>
                setFilterByTitle("exp", e.length ? e : undefined)
              }
              optionFilterProp="label"
              showSearch
              allowClear
              mode="multiple"
              placeholder={"Статья расходов"}
              options={expenses.map(({id, name, number}) => ({
                value: id,
                label: `${number} ${name}`,
              }))}
              value={filters.exp?.length ? Array.from(filters.exp, Number) : []}
            />
          </Space>
          <Space>
            <Select
              style={{minWidth: "300px"}}
              onChange={(e) => setFilterByTitle("firm", e)}
              allowClear
              placeholder={"Фирма"}
              options={firms.map(({id, name}) => ({value: id, label: name}))}
              value={firms.length && filters.firm}
            />
          </Space>
          <Space>
            <Select
              style={{minWidth: "300px", maxWidth: "300px"}}
              optionFilterProp="label"
              showSearch
              mode="multiple"
              allowClear
              value={filters.org?.length ? Array.from(filters.org, Number) : []}
              placeholder={"Филиал"}
              onChange={(e) => setOrgs(e)}
              options={orgs?.map((org) => ({
                value: org.id,
                label: org?.name || org?.title,
              }))}
            />
          </Space>
          {mode === "payment" && filters.mode === "passed" ? (
            <Space>
              <Select
                style={{minWidth: "300px", maxWidth: "300px"}}
                filterOption={false}
                allowClear
                showSearch
                placeholder="Автор согласования"
                value={filters.author}
                options={authors?.map((item) => {
                  return {
                    value: item.id,
                    label: `${item.f} ${item.i} ${item.o} ${item.email}`,
                  };
                })}
                onSearch={handleSearch}
                onChange={(e) => setFilterByTitle("author", e, true)}
              />
            </Space>
          ) : (
            ""
          )}

          <Space>
            <DatePicker.RangePicker
              format="DD.MM.YYYY"
              onCalendarChange={(e) => handleDatePicker(e, "create")}
              value={
                filters.create && filters.create.map((date) => dayjs(date))
              }
              placeholder={["Дата создания", ""]}
              style={{minWidth: "300px", maxWidth: "300px"}}
            />
          </Space>
          {mode === "payment" && filters.mode === "passed" ? (
            <Space>
              <DatePicker.RangePicker
                format="DD.MM.YYYY"
                onCalendarChange={(e) => handleDatePicker(e, "payDate")}
                value={
                  filters.payDate && filters.payDate.map((date) => dayjs(date))
                }
                placeholder={["Дата оплаты", ""]}
                style={{minWidth: "300px", maxWidth: "300px"}}
              />
            </Space>
          ) : (
            ""
          )}

          {filters.mode !== "forPass" ? (
            <>
              <Typography style={{fontWeight: 600, fontSize: "16px"}}>
                Статус счёта
              </Typography>
              <Space>
                <Checkbox
                  checked={filters.confirmation}
                  onChange={(e) =>
                    setFilterByTitle("confirmation", e.target.checked)
                  }
                >
                  На рассмотрении
                </Checkbox>
              </Space>
              <Space>
                <Checkbox
                  checked={filters.paid}
                  onChange={(e) => setFilterByTitle("paid", e.target.checked)}
                >
                  Оплачен
                </Checkbox>
              </Space>
              <Space>
                <Checkbox
                  checked={filters.canceled}
                  onChange={(e) =>
                    setFilterByTitle("canceled", e.target.checked)
                  }
                >
                  Отклонён
                </Checkbox>
              </Space>
            </>
          ) : (
            ""
          )}
          <div style={{display: "flex", gap: "12px", marginTop: "10px"}}>
            <Button style={{flex: "1 0 auto"}} onClick={resetFilters}>
              Сбросить
            </Button>
            <Button
              style={{flex: "1 0 auto"}}
              onClick={applyFilters}
              type="primary"
            >
              Применить
            </Button>
          </div>
        </Space>
      </Card>
    </div>
  );
};
